

@media (max-width: 500px) {
    .logo-container {
      margin-left: 20px; /* Adjust the margin-left as needed */
    }
    #champ {
      order: -1; /* Moves the Champion card to the top */
    }
    #one {
      order: 1; /* Moves the 1st Runner Up card to the second position */
    }
    #two {
      order: 2; /* Moves the 2nd Runner Up card to the first position */
    }
  }


  .carousel-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    border-width: 3px;
    border-color: #4793af;
    border-style: solid;
    max-width: 550px;
    margin: auto;
  }
  
  @media (max-width: 768px) {
    .carousel-image {
      max-width: 400px;
    }
  }
  
  @media (max-width: 576px) {
    .carousel-image {
      max-width: 300px;
    }
  }
  
  @media (max-width: 375px) {
    .carousel-image {
      max-width: 250px;
    }
  }

  @media (max-width:500px) {
    .p-4 h3 {
      font-size: 18px; /* Adjust the font size as needed */
    }
  }

  @media (max-width: 500px) {
    .col-md-4 {
      margin-bottom: 20px; /* Adjust the bottom margin as needed */
    }
  }
  
  @media (max-width: 500px){
    .lg-div{
      margin-left: 80px;
    }
  }

  @media (max-width: 400px){
    .lg-div{
      margin-left: 55px;
    }
  }

  .team-cards-section {
    padding: 20px;
    color: #fff;
  }
  
  .carousel-card-container {
    display: flex;
    justify-content: center;
  }
  
  .custom-card {
    width: 22rem;
    color: #fff;
    border-radius: 10px;
  }
  
  .circular-image {
    border-radius: 50%;
  }
  
  @media (max-width: 768px) {
    .carousel-card-container {
      display: flex;
      justify-content: center;
    }
    .custom-card {
      width: 90%;
      max-width: 300px;
    }
  }
  
  @media (min-width: 769px) {
    .team-cards-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }
    .carousel-card-container {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .custom-card {
      flex: 1 1 calc(33.33% - 40px); /* Adjust the width to allow three cards per row with some gap */
      max-width: 22rem;
    }
  }

  .team-cards-section {
    padding: 20px;
    color: #fff;
  }
  
  .carousel-card-container {
    display: flex;
    justify-content: center;
  }
  
  .custom-card {
    width: 22rem;
    color: #fff;
    border-radius: 10px;
  }
  
  .circular-image {
    border-radius: 50%;
  }
  
  @media (max-width: 768px) {
    .carousel-card-container {
      display: flex;
      justify-content: center;
    }
    .custom-card {
      width: 90%;
      max-width: 300px;
    }
  }
  
  @media (min-width: 769px) {
    .team-cards-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }
    .carousel-card-container {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .custom-card {
      flex: 1 1 calc(33.33% - 40px); /* Adjust the width to allow three cards per row with some gap */
      max-width: 22rem;
    }
  }
  
  