body {
    background-color: rgb(23, 23, 88);
    
    background-repeat: round;
    background-size: 800px 800px;
    background-attachment: fixed;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  Card.Text h5{
    color: #1342db;
  }

  /* Remove transformation on hover */
  .dark-variant-example .custom-card:hover {
    transform: translateZ(0px) !important; /* Remove any transformations */
    border-color:  rgba(46, 46, 46, 1)!important; /* Reset border color */
    box-shadow: none !important; /* Remove the box shadow */
    border-width: initial !important; /* Reset border width */
  }
  
  .circular-image {
    width: 110px; /* Adjust the width as needed */
    height: 138px; /* Ensure the height is the same as the width */
    /* border-radius: 10%;  */
    object-fit: cover; /* Cover the entire area */
    margin: 1px auto; /* Center the image horizontally */
  }
  
  .card-img-no-border {
    border: none !important;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  